.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.event-date {
  background-color: #14213D; /* Change this to the desired color */
  color: #FCA311;
}

.paragraph-container {
  margin-bottom: 20px; /* Adjust as needed */
}

/* Container for Breaking News */
.breaking-news-container {
  width: 100%;
  background-color: #060e22;
  color: rgb(175, 38, 84);
  padding: 15px;
  text-align: center;
  overflow: hidden; /* Hide overflowing content */
}

/* Breaking News animation */
@keyframes boom {
  0% { transform: scale(0.8); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.breaking-news {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  animation: boom 3s ease-in-out; /* Apply boom animation */
  
}

.sliding-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

.slide {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
}

.slide.active {
  top: 0;
  opacity: 1;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgb(22, 9, 58);
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
}

.dropdown-content a {
  display: block;
  padding: 8px 12px;
  color: #575786;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
  background-color: #020416;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@keyframes slideIn {
  from {
      top: -100%;
      opacity: 0;
  }
  to {
      top: 0;
      opacity: 1;
  }
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

